/** @define header **/
.header {
  padding: 32px 0 0;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 16px 0 0;
  }
  /* postcss-bem-linter: ignore */
  .container {
    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding: 0;
    }
  }
}

/** @define header-holder **/
.header-holder {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 3px;

  @include media-breakpoint-down(md) {
    padding: 0 30px;
    display: block;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 15px;
  }

  &__number {
    @include fontsize(24px, 27px);
    padding: 15px 0 0;
    font-weight: 700;
    display: block;

    @include media-breakpoint-down(lg) {
      font-size: 21px;
    }

    @include media-breakpoint-down(md) {
      @include fontsize(28px, 30px);
      border-top: 1px solid $black;
      text-align: center;
      padding: 16px 0;
      margin: 16px 0 0;
    }
  }

  &__link {
    @include v-align(inline-block, top);
    position: relative;
    color: $blue;
    /* postcss-bem-linter: ignore */
    .icon-phone {
      @include v-align(inline-block, top);
      font-size: 36px;
      margin: -3px -2px 0 0;

      @include media-breakpoint-down(lg) {
        font-size: 32px;
        margin: 0 -2px 0 0;
      }

      @include media-breakpoint-down(md) {
        font-size: 36px;
        margin: 0 5px 0 0;
      }
    }
    /* postcss-bem-linter: ignore */
    &:hover {
      color: $black;
    }
  }
}

/** @define logo **/
.logo {
  display: block;
  width: 219px;

  @include media-breakpoint-down(lg) {
    width: 190px;
  }

  @include media-breakpoint-down(md) {
    width: 108px;
  }
}

/** @define logo-link **/
.logo-link {
  display: block;
}

