/* stylelint-disable */
@media print {

  * {
    background: none !important;
    color: #000 !important;
    text-align: left !important;
  }

  .wrapper,
  .header,
  .header-holder,
  .main,
  .benifit-area,
  .benifit-text-area,
  .news-holder,
  .news-block,
  .news-block__image-holder,
  .bottom-area,
  .content-area,
  .content-block__description {
    padding: 0 !important;
    margin: 0 !important;
    display: block !important;
    text-align: left !important;
    overflow: visible;
    position: static !important;
    width: 100% !important;
    max-width: 100% !important;
    border: 0 !important;
  }

  .news-description__wrap {
    border: 0;
  }

  .container {
    padding: 0;
    max-width: 100% !important;
  }

  .row {
    margin: 0 !important;
    display: block !important;
  }

  [class^="col-"], [class*=" col-"] {
    padding: 0 !important;
    width: 100%;
    max-width: 100%;
  }

  .copyright-area__text02 {
    text-align: center;
  }

  .logo {
    margin: 0 auto 20px;
  }

  .content-block {
    margin: 0 0 30px !important;
  }

  .img,
  .hero-section,
  .nav-opener,
  .nav-drop,
  .started-area,
  .benifit-holder,
  .btn,
  .offer-area,
  .info-block-area,
  .footer-frame,
  .social-networks,
  .hero-section,
  .header-holder__number,
  .content-block__image-holder,
  .visual-area,
  .content-btn {
    display: none !important;
  }

  .logo .img {
    display: block !important;
  }
}
