/** @define footer **/
.footer {
  position: relative;
  overflow: hidden;
}

/** @define footer-frame **/
.footer-frame {
  border-bottom: 1px solid #bab8b6;
  border-top: 10px solid #d0ad7f;
  padding: 72px 0 70px;
  background: #f3f3f3;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 0 30px;
  }
}

/** @define footer-holder **/
.footer-holder {
  padding: 0 20px 0 32px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    text-align: center;
    padding: 0;
  }
}

/** @define footer-nav **/
.footer-nav {
  @include fontsize(18px, 22px);
  position: relative;
  margin: 0;
  @include media-breakpoint-down(md) {
    margin: 0 0 30px;
  }

  /* stylelint-disable */
  li {
    position: relative;
    margin: 0 0 28px;
    @include media-breakpoint-down(md) {
      margin: 0 0 15px;
    }
    /* postcss-bem-linter: ignore */
    &:last-child {
      margin-bottom: 0;
    }
    a {
      @include v-align(inline-block, top);
      position: relative;
      padding: 0 0 0 19px;
      color: #161616;
      /* postcss-bem-linter: ignore */
      &::before {
        border-style: solid;
        border-width: 5px 0 5px 7px;
        border-color: transparent transparent transparent #161616;
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
      }
      /* postcss-bem-linter: ignore */
      &:hover {
        color: $blue;
        /* postcss-bem-linter: ignore */
        &::before {
          border-color: transparent transparent transparent $blue;
        }
      }
    }
  }
  /* stylelint-enable */
}

/** @define footer-info **/
.footer-info {
  position: relative;
  overflow: hidden;
  text-align: right;

  @include media-breakpoint-down(md) {
    text-align: center;
  }

  &__heading03 {
    font-weight: 300;
    margin: 0 0 25px;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }
  /* postcss-bem-linter: ignore */
  .logo {
    @include v-align(inline-block, top);
  }
  /* postcss-bem-linter: ignore */
  address {
    @include fontsize(18px, 22px);
    letter-spacing: 0.3px;
    font-weight: 700;
    padding: 32px 0 15px;
    margin: 0 -10px 0 0;
    @include media-breakpoint-down(sm) {
      margin: 0;
      padding: 30px 0;
      font-size: 17px;
    }
    /* postcss-bem-linter: ignore */
    a {
      color: #282828;
      /* postcss-bem-linter: ignore */
      &:hover {
        color: $blue;
      }
    }
  }
}

/** @define adress-text **/
.adress-text {
  @include v-align(inline-block, top);
  position: relative;
  padding: 0 7px;
  @include media-breakpoint-down(sm) {
    padding: 0;
    margin: 0 0 10px;
    display: block;
  }
  /* stylelint-disable */
  &:first-child {
    &:before {
      display: none;
    }
  }
  &:before {
    background: #282828;
    width: 2px;
    content: '';
    position: absolute;
    left: -1px;
    top: 2px;
    bottom: 0;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  /* stylelint-enable */
}

/** @define contact-list **/
.contact-list {
  @include fontsize(24px, 28px);
  font-weight: 300;
  position: relative;
  overflow: hidden;
  margin: 0 -12px;
  @include media-breakpoint-down(lg) {
    margin: 0 -6px;
  }
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
  /* stylelint-disable */
  &__item01 {
    @include v-align(inline-block, top);
    position: relative;
    padding: 0 15px;
    @include media-breakpoint-down(lg) {
      padding: 0 6px;
    }
    @include media-breakpoint-down(sm) {
      padding: 0;
      display: block;
      margin: 0 0 18px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
    color: #b28c59;
  }
  &__link01 {
    color: #282828;
    &:hover {
      color: $blue;
    }
  }
  /* stylelint-enable */
}

/** @define bottom-area **/
.bottom-area {
  padding: 7px 0;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding: 16px 0;
  }
}

/** @define copyright-area **/
.copyright-area {
  padding: 0 11px 0 30px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #161616;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
    justify-content: center;
  }

  &__text02 {
    font-size: 16px;
    font-weight: 700;
    display: block;
    margin: 0;
    line-height: 20px;
    padding: 15px 0;
    @include media-breakpoint-down(sm) {
      order: 2;
    }
    /* stylelint-disable */
    a {
      color: #161616;
      &:hover {
        color: $blue;
      }
    }
  }
}

/** @define social-networks **/
.social-networks {
  position: relative;
  font-size: 34px;
  margin: 0;
  @include media-breakpoint-down(sm) {
    order: 1;
    margin: 0 0 2px;
  }

  &__item02 {
    position: relative;
  }

  &__link02 {
    position: relative;
    color: #d0ad7f;

    &:hover {
      color: $blue;
    }
  }
}

.privacy-link {
  color: #161616;
  font-size: 16px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
  padding: 13px 0;
  font-weight: 700;
  a {
    color: #161616;
    &:hover {
      color: $blue;
    }
  }
}

