/** @define hero-section **/
.hero-section {
  position: relative;
  overflow: hidden;
}

/** @define alert-area **/
.alert-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  @include media-breakpoint-down(sm) {
    position: relative;
  }
  /* postcss-bem-linter: ignore */
  .container {
    position: relative;
    max-width: 884px;
    left: 23px;
    @include media-breakpoint-down(lg) {
      left: 0;
    }
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
}

/** @define alert-bar **/
.alert-bar {
  @include fontsize(20px, 24px);
  padding: 12px 54px 14px 5px;
  letter-spacing: 0.6px;
  background: $red;
  color: $white;
  text-align: center;
  position: relative;
  @include media-breakpoint-down(lg) {
    padding: 12px 5px 14px;
  }
  @include media-breakpoint-down(sm) {
    padding: 12px 18px 14px;
    text-align: left;
  }
}

/** @define carousel **/
.carousel {
  position: relative;

  .img {
    @media (max-width: 1600px) {
      position: relative;
      max-width: inherit;
      left: 50%;
      margin: 0 0 0 -800px;
      width: 1600px;
    }
    @include media-breakpoint-down(sm) {
      width: 1000px;
      margin: 0 0 0 -500px;
    }
  }
}

/** @define carousel-caption **/
.carousel-caption {
  @include position;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: left;
  align-items: center;
  color: $black;
  /* stylelint-disable */
  &.white {
    color: $white;
    .caption__link {
      color: $white;
      &:hover {
        color: #ed1c24;
        opacity: 0.8;
      }
    }
  }
}

/** @define caption **/
.caption {
  position: relative;
  max-width: 505px;
  padding: 30px 0 0;
  left: 2px;
  @include media-breakpoint-down(md) {
    left: 0;
    max-width: 415px;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }

  &__heading {
    @include fontsize(80px, 78px);
    letter-spacing: -2.6px;
    margin: 0 0 21px;
    @include media-breakpoint-down(md) {
      @include fontsize(65px, 68px);
    }
    @include media-breakpoint-down(sm) {
      @include fontsize(40px, 40px);
      padding: 0 20px 0 0;
      letter-spacing: -1px;
      margin: 0 0 8px;
    }
  }

  &__call-text {
    @include fontsize(21px, 24px);
    display: block;
    @include media-breakpoint-down(sm) {
      @include fontsize(16px, 24px);
      width: 237px;
    }
  }

  &__link {
    @include v-align(inline-block, top);
    font-size: 26px;
    color: $black;
    &:hover {
      color: #ed1c24;
      opacity: 0.8;
    }
  }
}

/** @define slider-btn **/
.slider-btn {
  @include fontsize(16px, 20px);
  @include size(388px, 62px);
  border: 1px solid rgba(14, 58, 70, 0.61);
  font-weight: 900;
  box-shadow: 0 2px #266f83;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  background: #41b1d0;
  border-radius: 4px;
  color: $white;
  position: relative;
  padding: 21px 5px 5px;
  white-space: normal;
  display: block;
  margin: 0 0 22px;
  @include media-breakpoint-down(sm) {
    @include fontsize(14px, 18px);
    @include size(284px, 50px);
    padding: 16px 5px 5px;
    letter-spacing: 1px;
    margin: 0 0 8px;
  }
  /* postcss-bem-linter: ignore */
  &:hover {
    background: #266f83;
    color: $white;
  }
}

/** @define carousel-indicators **/
.carousel-indicators {
  margin: 0;
  bottom: 32px;

  @include media-breakpoint-down(sm) {
    bottom: 10px;
  }

  li {
    @include fontsize(0, 0);
    @include size(16px, 16px);
    transition: all 0.4s ease;
    background: $brown;
    position: relative;
    border-radius: 50%;
    margin: 0 11px;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      margin: 0 5px;
    }
    &:before,
    &:after {
      display: none;
    }
    &:hover,
    &.active {
      background: $white;
    }
  }
}
