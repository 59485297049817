// custom variables and override bootstrap variables
$gray: #656565;
$white: #fff;
$black: #000;
$gray2: #f6f6f6;
$gray3: #363636;
$base-min-width: 320px;
// This is the default html and body font-size for the base rem value.
$theme-colors: (
  primary: #f1764b,
  secondary: #f7941d,
);
$grid-breakpoints: (
  xs: 0, 
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1176px
);

$mine-shaft: #282828;
$blue: #41b1d0;
$light-black: #161616;
$brown: #332b21;
$red: #ed1c24;

$arial-font: 'Arial', 'Helvetica', sans-serif;
$body-color: $mine-shaft;
$link-color: $blue;
$link-hover-decoration: none;
$font-family-base: 'Lato', $arial-font;
$btn-transition: all 0.4s ease;
$headings-font-weight: 400;
$h1-font-size: 80px;
$h2-font-size: 40px;
$h3-font-size: 30px;
$line-height-base: 2.1;
