/** @define nav-opener **/
.nav-opener {
  /* stylelint-disable */
  @include size(27px, 18px);
  border-top: 4px solid #5d5d5d;
  transition: all 0.4s ease;
  position: absolute;
  right: 30px;
  top: 8px;
  display: none;
  z-index: 9;

  @include media-breakpoint-down(md) {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    right: 15px;
  }

  &:before,
  &:after {
    @include size(27px, 4px);
    transition: all 0.4s ease;
    content: '';
    background: #5d5d5d;
    position: absolute;
    left: 0;
    top: 3px;
  }
  &:after {
    top: 10px;
  }
}

/** @define nav-drop **/
.nav-drop {
  position: relative;
  @include media-breakpoint-down(md) {
    transition: all 0.4s ease;
    position: absolute;
    top: 47px;
    left: 0;
    right: 0;
    overflow: hidden;
    max-height: 0;
    z-index: 99;
  }
}

/** @define nav **/
.nav {
  position: relative;
  @include media-breakpoint-down(md) {
    transition: all 0.4s ease;
    transform: translateY(-100%);
  }
}

/** @define menu **/
.menu {
  @include fontsize(18px, 22px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 18px 8px 0 0;

  @include media-breakpoint-down(lg) {
    margin: 18px -5px;
  }

  @include media-breakpoint-down(md) {
    background: $white;
    padding: 0 30px 50px;
    display: block;
    margin: 0;
    width: 100%;
  }

  @include media-breakpoint-down(sm) {
    padding: 0 15px 50px;
  }

  li {
    position: relative;
    padding: 0 14px 44px;

    @include media-breakpoint-down(lg) {
      padding: 0 5px 44px;
    }
    @include media-breakpoint-down(md) {
      border-bottom: 1px solid #5d5d5d;
      padding: 0;
      &:last-child {
        border-bottom: 0;
      }
      /* postcss-bem-linter: ignore */
      &.show {
        .dropdown-toggle::after {
          transform: rotate(0);
          margin-top: 0;
        }
      }
    }

    &:hover {
      .dropdown-menu {
        @media (min-width: 992px) {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    a {
      @include v-align(inline-block, top);
      color: $light-black;
      position: relative;
      padding: 0;
      @include media-breakpoint-down(md) {
        padding: 17px 0;
        display: block;
      }
      &:hover {
        color: $blue;
      }
    }

  .dropdown-toggle {
    &::after {
      display: none;
      @include media-breakpoint-down(md) {
        display: inline-block;
        vertical-align: top;
        content: "\e907";
        font-family: 'icomoon' !important;
        border: 0;
        margin: 20px 0 0 5px;
        transition: all 0.4s ease;
        transform: rotate(-90deg);
      }
    }
    &::before {
      font-family: 'icomoon' !important;
      content: "\e907";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -18px;
      text-align: center;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
  }
}

/** @define dropdown-menu **/
.dropdown-menu {
  padding: 12px 30px 5px;
  border: 0;
  border-top: 2px solid #41b1d0;
  box-shadow: none;
  border-radius: 0;
  font-size: 18px;
  width: 282px;
  margin: 0;
  transform: none !important;
  top: 100% !important;
  left: -5px;

  @media (min-width: 992px) {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
  }

  @include media-breakpoint-down(md) {
    position: static !important;
    width: 100% !important;
    padding: 10px 13px 20px;
    float: none;
    font-weight: 700;
    border-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }

  li {
    padding: 0 0 28px;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

}

/** @define dropdown-item **/
.dropdown-item {
  position: relative;
  margin: 0 0 28px;
  padding: 0;
  @include media-breakpoint-down(md) {
    font-weight: 700;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover,
  &:focus {
    background: none;
  }
}

/** @define dropdown-link **/
.dropdown-link {
  color: $light-black;
  @include media-breakpoint-down(md) {
    color: $blue;
  }
  &:hover {
    color: $blue;
    @include media-breakpoint-down(md) {
      color: $light-black;
    }
  }
}

/** @define nav-acitve **/
.nav-active {
  .nav-opener {
    border-top: 0;
    top: 13px;
    &::after,
    &::before {
      transform: rotate(45deg);
      top: 3px;
    }
    &::before {
      transform: rotate(-45deg);
      top: 3px;
    }
  }
  .nav-drop {
    max-height: 4000px;
  }
  .nav {
    transform: translateY(0);
  }
}

  /* stylelint-enable */
