/** @define offer-area **/
.offer-area {
  background: #130c04;
  padding: 126px 0 117px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 70px 0 62px;
  }

  /* postcss-bem-linter: ignore */
  &::before {
    @include size(32.8vw, 10.15vw);
    background: url('../img/flag2.jpg') no-repeat;
    background-size: cover;
    content: '';
    position: absolute;
    left: 4.3vw;
    top: 0;
    @include media-breakpoint-down(sm) {
      @include size(55vw, 17vw);
      left: -8vw;
    }
  }
  /* postcss-bem-linter: ignore */
  &::after {
    @include size(81.4vw, 23.5vw);
    background: url('../img/flag1.jpg') no-repeat;
    background-size: cover;
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    @include media-breakpoint-down(sm) {
      @include size(105vw, 44vw);
    }
  }
}

/** @define offer-holder **/
.offer-holder {
  position: relative;
  margin-bottom: 2px;
  z-index: 5;

  &__tag-holder {
    box-shadow: 0 4px #103640;
    background: #1eb0d1;
    padding: 0 3px 0 20px;
    height: 50px;
    position: absolute;
    left: 160px;
    top: 200px;
    right: 20px;
    z-index: 2;
    @include media-breakpoint-down(lg) {
      padding: 0 3px 0 50px;
      left: 75px;
      top: 170px;
      right: 0;
    }
    @include media-breakpoint-down(md) {
      padding: 0 3px;
      top: 110px;
      left: 50%;
      transform: translateX(-50%);
      width: 380px;
      margin: 0 0 0 60px;
      right: auto;
    }
    @include media-breakpoint-down(sm) {
      top: 80px;
      height: 24px;
      width: 200px;
      transform: none;
      padding: 0 3px;
      margin: 0 0 0 -80px;
    }
    /* stylelint-disable */
    .shape01 {
      &:after,
      &:before {
        content: '';
        position: absolute;
        left: -25px;
        top: 0;
        border-style: solid;
        border-width: 0 25px 25px 0;
        border-color: transparent #1eb0d1 transparent transparent;

        @include media-breakpoint-down(sm) {
          border-width: 0 12px 12px 0;
          left: -12px;
        }
      }
      &:before {
        border-color: transparent #103640 transparent transparent;
        top: 4px;
      }
    }
    .shape02 {
      &:after,
      &:before {
        position: absolute;
        content: '';
        border-style: solid;
        border-width: 0 0 25px 25px;
        border-color: transparent transparent #1eb0d1 transparent;
        left: -25px;
        bottom: 0;

        @include media-breakpoint-down(sm) {
          border-width: 0 0 12px 12px;
          left: -12px;
        }
      }
      &:before {
        border-color: transparent transparent #103640 transparent;
        bottom: -3px;
        left: -27px;

        @include media-breakpoint-down(sm) {
          left: -12px;
        }
      }
    }
  }

  &__tag {
    @include fontsize(31px, 49px);
    text-transform: uppercase;
    height: 100%;
    letter-spacing: 1.5px;
    color: #130c04;
    display: block;
    @include media-breakpoint-down(lg) {
      font-size: 26px;
      letter-spacing: 1px;
    }
    @include media-breakpoint-down(sm) {
      @include fontsize(14px, 24px);
      letter-spacing: 0.5px;
    }
    &:after {
      content: '';
      position: absolute;
      right: -24px;
      top: 0;
      border-style: solid;
      border-width: 25px 0 25px 25px;
      border-color: transparent transparent transparent #1eb0d1;

      @include media-breakpoint-down(sm) {
        border-width: 12px 0 12px 12px;
        right: -12px;
      }
    }
    &:before {
      content: '';
      position: absolute;
      right: -24px;
      top: 3px;
      border-style: solid;
      border-width: 25px 0 25px 25px;
      border-color: transparent transparent transparent #103640;

      @include media-breakpoint-down(sm) {
        border-width: 12px 0 12px 12px;
        right: -12px;
      }
    }
  }
}

/** @define number-block **/
.number-block {
  position: relative;
  margin: 29px 0 0 -41px;

  @include media-breakpoint-down(lg) {
    margin: -20px 0 0;
  }

  @include media-breakpoint-down(md) {
    text-align: center;
    margin: 0 0 50px;
  }
}

/** @define number-holder **/
.number-holder {
  @include fontsize(388px, 310px);
  text-shadow: 0 12px rgba(129, 104, 71, 0.64);
  letter-spacing: -63px;
  font-weight: 400;
  color: #d0ad7f;
  position: relative;
  display: block;

  @include media-breakpoint-down(lg) {
    font-size: 300px;
    letter-spacing: -46px;
  }

  @include media-breakpoint-down(md) {
    line-height: 200px;
  }

  @include media-breakpoint-down(sm) {
    @include fontsize(178px, 124px);
    letter-spacing: -32px;
    left: -16px;
  }

  &__number {
    @include v-align(inline-block, top);
    position: relative;
    /* postcss-bem-linter: ignore */
    &:nth-child(2) {
      z-index: 3;
    }
  }
}

/** @define video-holder **/
.video-holder {
  position: relative;
  width: 95%;
  left: 7px;

  @include media-breakpoint-down(md) {
    left: 0;
    width: 100%;
  }

  &__video {
    width: 100%;
    min-height: 350px;
  }

  &__placeholder {
    position: absolute;
    top: 0;
  }

  &__video-link {
    border: 10px solid #271c0e;
    border-radius: 4px;
    position: relative;
    display: block;
    overflow: hidden;
    /* postcss-bem-linter: ignore */
    &:hover {
      opacity: 0.8;
    }
  }

  &__play-btn {
    @include size(140px, 140px);
    border: 4px solid rgba(65, 177, 208, 0.61);
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    margin: -70px 0 0 -70px;
    z-index: 2;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      @include size(70px, 70px);
      margin: -35px 0 0 -35px;
    }
    /* postcss-bem-linter: ignore */
    &:hover {
      /* postcss-bem-linter: ignore */
      &::after {
        border-color: transparent transparent transparent rgba(208, 173, 127, 0.61);
      }
    }
    /* postcss-bem-linter: ignore */
    &::before {
      @include size(118px, 118px);
      border: 4px solid rgba(208, 173, 127, 0.61);
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 50%;
      margin: -59px 0 0 -59px;
      @include media-breakpoint-down(sm) {
        @include size(60px, 60px);
        margin: -30px 0 0 -30px;
      }
    }
    /* postcss-bem-linter: ignore */
    &::after {
      border-style: solid;
      border-width: 28px 0 28px 38px;
      border-color: transparent transparent transparent #41b1d0;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -28px 0 0 -12px;
      @include media-breakpoint-down(sm) {
        border-width: 13px 0 13px 20px;
        border-color: transparent transparent transparent #41b1d0;
        margin: -13px 0 0 -7px;
      }
    }
  }
}

/** @define offer-info **/
.offer-info {
  padding: 25px 0 0;
  position: relative;
  overflow: hidden;
  text-align: center;
  font-size: 30px;
  color: #41b1d0;
  font-weight: 300;
  left: 7px;
  z-index: 5;

  @include media-breakpoint-down(lg) {
    font-size: 25px;
    left: 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 18px 0 0;
    font-size: 16px;
  }

  &__title {
    @include fontsize(66px, 66px);
    font-weight: 300;
    display: block;
    margin: 0 0 4px;

    @include media-breakpoint-down(lg) {
      @include fontsize(60px, 60px);
    }

    @include media-breakpoint-down(sm) {
      @include fontsize(24px, 32px);
    }
  }

  &__text03 {
    margin: 0 0 33px;

    @include media-breakpoint-down(sm) {
      margin: 0 0 18px;
    }
  }
}

/** @define offer-btn **/
.offer-btn {
  @include fontsize(16px, 20px);
  @include size(303px, 62px);
  box-shadow: 0 2px rgba(129, 104, 71, 0.07);
  background: none;
  border: 2px solid #b29064;
  border-radius: 4px;
  display: block;
  margin: 0 auto;
  letter-spacing: 1.4px;
  padding: 19px 5px 5px;
  color: #d0ad7f;
  text-transform: uppercase;
  font-weight: 900;
  left: -13px;
  position: relative;

  @include media-breakpoint-down(lg) {
    left: 0;
  }
  @include media-breakpoint-down(sm) {
    @include size(100%, 50px);
    font-size: 14px;
    letter-spacing: 1px;
    padding: 14px 5px 5px;
  }
  /* postcss-bem-linter: ignore */
  &:hover {
    background: none;
    color: $white;
    border-color: $white;
  }
}

.offer-holder__tag {
  span {
    text-align: left;
    @include v-align(inline-block, top);
    margin: 0 58px 0 0;
    @include media-breakpoint-down(sm) {
      margin: 0 35px 0 0;
    }
  }
}

.inner__video {
  width: 100%;
  margin-bottom: 25px;
}