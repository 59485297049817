/** @define benifit-area **/
.benifit-area {
  padding: 108px 0 106px;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 70px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 35px 0 20px;
  }
}

/** @define benifit-holder **/
.benifit-holder {
  position: relative;
  padding: 0 8px;
  margin-bottom: 96px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
    padding: 0;
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: 10px;
  }
}

/** @define benifit-col **/
.benifit-col {
  position: relative;

  @include media-breakpoint-down(md) {
    margin: 0 0 50px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 0 36px;
  }
}

/** @define benifit-block **/
.benifit-block {
  position: relative;
  padding: 0 8px;

  &__link01 {
    padding: 10px 0 0;
    border-radius: 4px;
    position: relative;
    background: rgba(61, 175, 207, 0.98);
    display: block;
    color: $white;
    /* postcss-bem-linter: ignore */
    &::after {
      border: 1px solid rgba(61, 175, 207, 0.61);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
      content: '';
      position: absolute;
      bottom: -8px;
      left: 6px;
      right: 6px;
      background: $white;
      height: 8px;
    }
    /* postcss-bem-linter: ignore */
    &::before {
      border: 1px solid rgba(61, 175, 207, 0.61);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
      content: '';
      position: absolute;
      bottom: -15px;
      left: 12px;
      right: 12px;
      background: $white;
      height: 8px;
    }
    /* postcss-bem-linter: ignore */
    &:hover {
      background: #277084;
      color: $white;
    }
  }
}

/** @define benifit-image **/
.benifit-image {
  border: 1px solid rgba(61, 175, 207, 0.61);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  position: relative;
  margin: 0 -8px;
  /* postcss-bem-linter: ignore */
  .icon-holder {
    @include size(98px, 98px);
    background: #d0ad7f;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -49px 0 0 -49px;
    /* postcss-bem-linter: ignore */
    img {
      display: block;
    }
  }
  /* postcss-bem-linter: ignore */
  .img {
    border-radius: 4px;
  }
}

/** @define benifit-description **/
.benifit-description {
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 18px 5px;
  @include media-breakpoint-down(lg) {
    padding: 13px 5px 18px;
  }

  @include media-breakpoint-down(sm) {
    padding: 14px 5px 16px;
  }

  /* postcss-bem-linter: ignore */
  .name {
    @include fontsize(24px, 28px);
    font-weight: 400;
    display: block;

    @include media-breakpoint-down(sm) {
      @include fontsize(20px, 24px);
      /* postcss-bem-linter: ignore */
      br {
        display: none;
      }
    }
  }
}

/** @define benifit-text-area **/
.benifit-text-area {
  position: relative;
  text-align: center;
  padding: 0 70px;
  color: #130c04;
  left: -5px;

  @include media-breakpoint-down(lg) {
    padding: 0;
    left: 0;
  }

  &__heading02 {
    @include fontsize(40px, 44px);
    font-weight: 300;
    margin: 0 0 27px;

    @include media-breakpoint-down(sm) {
      @include fontsize(24px, 30px);
      margin: 0 0 15px;
    }
  }

  &__text02 {
    letter-spacing: 0.5px;
    margin: 0 0 41px;
    display: block;

    @include media-breakpoint-down(sm) {
      margin: 0 0 20px;
      letter-spacing: 0;
    }
  }
}

/** @define info-block-area **/
.info-block-area {
  position: relative;
  overflow: hidden;
  @include media-breakpoint-down(md) {
    overflow: visible;
  }
}

/** @define block-col **/
.block-col {
  position: relative;
  z-index: 2;
  max-width: inherit;
  /* stylelint-disable */
  &.blue {
    background: #59c8e6;
    color: $white;
    @media (min-width: 1200px) {
      left: 18px;
      flex: 0 0 30.833%;
    }
    &:before {
      background: #59c8e6;
      content: '';
      position: absolute;
      left: -9999px;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    .info-block__text04 {
      text-transform: uppercase;
      max-width: 195px;
    }
  }
  &.brown {
    background: #d0ad7f;
    color: #1c150c;

    @media (min-width: 1200px) {
      flex: 0 0 37.033%;
    }

    .info-block {
      padding: 93px 0 112px;

      @include media-breakpoint-down(lg) {
        padding: 55px 0 115px;
      }

      @include media-breakpoint-down(sm) {
        padding: 50px 0 80px;
      }
    }
  }
  &.black {
    background: #130c04;
    color: #d0ad7f;
    @media (min-width: 1200px) {
      flex: 0 0 31%;
      .info-block {
        padding: 49px 0 121px 32px;
      }
    }
    &:before {
      background: #130c04;
      content: '';
      position: absolute;
      right: -9999px;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: -1;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

/** @define info-block **/
.info-block {
  position: relative;
  padding: 58px 0 112px;
  text-align: center;
  height: 100%;

  @include media-breakpoint-down(lg) {
    padding: 40px 0 115px;
  }

  @include media-breakpoint-down(md) {
    padding: 30px 0 150px;
  }
  @include media-breakpoint-down(sm) {
    padding: 40px 0 80px;
  }

  &__title04 {
    @include fontsize(66px, 60px);
    text-transform: uppercase;
    font-weight: 400;
    display: block;
    margin: 0 0 6px;
    @include media-breakpoint-down(sm) {
      @include fontsize(42px, 44px);
    }
    sub {
      font-size: 70%;
      bottom: 0;
    }
  }

  &__sub-text {
    display: block;

    @include media-breakpoint-down(sm) {
      display: inline-block;
      vertical-align: top;
    }
  }

  &__text04 {
    @include fontsize(18px, 22px);
    max-width: 305px;
    margin: 0 auto;
  }

  &__btn-holder {
    text-align: center;
    display: block;
    margin: 17px 0 0;
  }

  &__btn {
    @include fontsize(16px, 20px);
    @include v-align(inline-block, top);
    box-shadow: 0 2px rgba(129, 104, 71, 0.07);
    border: 2px solid #1c150c;
    text-transform: uppercase;
    padding: 18px 26px 5px;
    letter-spacing: 1.4px;
    font-weight: 900;
    background: none;
    color: #1c150c;
    height: 62px;
    border-radius: 4px;

    @include media-breakpoint-down(lg) {
      font-size: 15px;
      padding: 18px 20px 5px;
    }

    @include media-breakpoint-down(sm) {
      padding: 14px 20px 5px;
      font-size: 14px;
      height: 50px;
      letter-spacing: 1px;
    }

    &:hover {
      color: $white;
      border-color: $white;
    }
  }

  .image-wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    @include media-breakpoint-down(sm) {
      width: 84px;
      left: 50%;
      margin: 0 0 0 -42px;
    }
    &.style01 {
      bottom: -43px;
      @include media-breakpoint-down(sm) {
        bottom: -30px;
      }
    }
    img {
      display: block;
      margin: 0 auto;
      @include media-breakpoint-down(sm) {
        width: 100%;
        height: auto;
      }
    }
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .block-col {
    @media (min-width: 1200px) {
      max-width: 33.3333%;
    }
  }
  
}