/* stylelint-disable */
body {
  min-width: 320px;
  font-size: 18px;
  line-height: 1.5556;

  @include media-breakpoint-down(md) {
    font-size: 18px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

/** @define anchor **/
a {
  transition: all 0.4s ease;
  text-decoration: none;
}

.btn-primary {
  @include fontsize(16px, 20px);
  @include size(332px, 62px);
  font-weight: 900;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding: 19px 5px 5px;
  border-radius: 4px;
  display: block;
  color: #130c04;
  box-shadow: 0 2px #816847;
  border: 1px solid rgba(178, 144, 100, 0.61);
  background: #d0ad7f;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    @include size(100%, 50px);
    font-size: 14px;
    padding: 14px 5px 5px;
    letter-spacing: 1px;
  }
  &:hover,
  &:focus {
    border: 1px solid rgba(178, 144, 100, 0.61);
    box-shadow: 0 2px #816847;
    color: #130c04;
    background: #e9d9c4;
  }
}

.img {
  @include img-style;
}

.wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.main {
  position: relative;
}

.started-area {
  background: #d0ad7f;
  padding: 10px 0;
  position: relative;
  overflow: hidden;
  text-align: center;

  @include media-breakpoint-down(sm) {
    padding: 12px 0 15px;
    text-align: left;
  }

  &__link-wrap {
    @include v-align(inline-block, middle);
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 700;
    position: relative;
    margin: 0 0 0 16px;

    @include media-breakpoint-down(sm) {
      display: block;
      font-size: 24px;
      line-height: 26px;
      margin: 5px 0 0;
    }
  }

  &__link {
    text-decoration: underline;
    color: $black;

    &:hover {
      text-decoration: underline;
      color: $white;
    }
  }
}

.started-holder {
  font-size: 20px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 20px;
  }
}