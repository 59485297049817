/** @define content-area **/
.content-area {
  padding: 95px 0 10px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    padding: 20px 0 10px;
  }
}

/** @define heading-area **/
.heading-area {
  position: relative;
  overflow: hidden;
  margin: 0 0 81px;

  @include media-breakpoint-down(sm) {
    margin: 0 0 30px;
    text-align: center;
  }

  &__heading02 {
    font-weight: 300;
    margin: 0 0 8px;

    @include media-breakpoint-down(sm) {
      @include fontsize(24px, 28px);
    }
  }

  &__text02 {
    letter-spacing: 0.5px;
    margin: 0;

    @include media-breakpoint-down(sm) {
      letter-spacing: 0;
    }
  }
}

/** @define content-holder **/
.content-holder {
  position: relative;
  padding: 0 50px;
  @media (min-width: 1200px) {
    margin: 0 -51px;
  }
  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

/** @define content-col **/
.content-col {
  position: relative;
  @media (min-width: 1200px) {
    padding: 0 51px;
  }
}

/** @define content-block **/
.content-block {
  position: relative;
  margin: 0 0 150px;

  @include media-breakpoint-down(md) {
    margin: 0 0 80px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 0 55px;
  }

  &__image-holder {
    border: 1px solid rgba(61, 175, 207, 0.61);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    position: relative;
    padding: 14px 15px 0;
    @include media-breakpoint-down(sm) {
      padding: 8px 8px 0;
    }
    /* postcss-bem-linter: ignore */
    &::before {
      border: 1px solid rgba(61, 175, 207, 0.61);
      border-bottom: 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
      content: '';
      position: absolute;
      top: -10px;
      left: 12px;
      right: 12px;
      height: 10px;
      @include media-breakpoint-down(sm) {
        left: 8px;
        top: -8px;
        right: 8px;
        height: 8px;
      }
    }
    /* postcss-bem-linter: ignore */
    &::after {
      border: 1px solid rgba(61, 175, 207, 0.61);
      border-top: 0;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
      content: '';
      position: absolute;
      bottom: -10px;
      left: 12px;
      right: 12px;
      height: 10px;
      @include media-breakpoint-down(sm) {
        bottom: -8px;
        left: 8px;
        right: 8px;
        height: 8px;
      }
    }
  }
  /* postcss-bem-linter: ignore */
  .img {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
  }

  &__image-caption {
    position: relative;
    overflow: hidden;
    text-align: center;
    margin: 0 -10px;
    @include media-breakpoint-down(lg) {
      margin: 0;
    }
  }

  &__name {
    @include fontsize(28px, 32px);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 15px 0;
    height: 94px;
    color: $blue;

    @include media-breakpoint-down(lg) {
      @include fontsize(25px, 30px);
      min-height: 94px;
      height: auto;
    }

    @include media-breakpoint-down(md) {
      @include fontsize(24px, 28px);
      padding: 10px 0;
    }

    @include media-breakpoint-down(sm) {
      @include fontsize(20px, 22px);
      padding: 7px 0 10px;
      min-height: 56px;
    }
  }

  &__wrap {
    width: 100%;
    display: block;
  }

  &__sub-text {
    display: block;
  }

  &__description {
    letter-spacing: 0.5px;
    position: relative;
    padding: 37px 12px 0;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 22px 0 0;
    }
  }
  /* postcss-bem-linter: ignore */
  p {
    margin: 0 0 30px;

    @include media-breakpoint-down(sm) {
      margin: 0 0 10px;
    }
  }
}

/** @define content-btn **/
.content-btn {
  @include fontsize(16px, 20px);
  @include size(250px, 48px);
  font-weight: 900;
  letter-spacing: 1.8px;
  text-transform: uppercase;
  padding: 14px 5px 5px;
  border-radius: 4px;
  display: block;
  color: $white;
  box-shadow: 0 2px #3b94ac;
  border: solid 1px rgba(54, 163, 192, 0.61);
  background: #59c8e6;
  position: relative;
  margin: 0 auto;
  left: 4px;
  @media (min-width: 1200px) {
    left: 0;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    font-size: 14px;
    letter-spacing: 1px;
  }

  &:hover,
  &:focus {
    background: #266f83;
    color: $white;
  }
}

/* stylelint-disable */
.gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 0 0;
  position: relative;

  .gallery-item {
    text-align: center;
    margin: 0 0 30px;
    width: 23%;
    padding: 0 0 20px;
    @include media-breakpoint-down(lg) {
      width: 32%;
    }
    @include media-breakpoint-down(md) {
      width: 48%;
    }
    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    img {
      display: block;
      max-width: 100%;
      margin: 0 auto;
      height: auto;
    }
  }

  .gallery-caption {
    padding: 0 10px;
  }
}

.acf-map {
  width: 100%;
  height: 400px;
  border-top: #ccc solid 1px;
}
.acf-map img {
   max-width: inherit !important;
}
