/** @define visual-area **/
.visual-area {
  position: relative;
  overflow: hidden;
  /* postcss-bem-linter: ignore */
  .img {
    @media (max-width: 1600px) {
      position: relative;
      max-width: inherit;
      left: 50%;
      margin: 0 0 0 -800px;
      width: 1600px;
    }
    @include media-breakpoint-down(sm) {
      width: 1640px;
      margin: 0 0 0 -640px;
    }

    @media (max-width: 400px) {
      margin: 0 0 0 -540px;
      height: 185px;
    }
  }
}

/** @define visual-caption-area **/
.visual-caption-area {
  @include position;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* postcss-bem-linter: ignore */
  h1 {
    margin: 9px 0 0;
    letter-spacing: -1.5px;
    @include media-breakpoint-down(lg) {
      margin: 0;
    }

    @include media-breakpoint-down(md) {
      @include fontsize(65px, 68px);
    }

    @include media-breakpoint-down(sm) {
      @include fontsize(40px, 42px);
      letter-spacing: -0.5px;
    }
  }
}
