/** @define news-area **/
.news-area {
  padding: 133px 0 145px;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    padding: 70px 0 30px;
  }

  @include media-breakpoint-down(sm) {
    padding: 50px 0 10px;
  }

  &__heading {
    font-weight: 300;
    text-align: center;
    margin: 0 0 46px;

    @include media-breakpoint-down(md) {
      margin: 0 0 30px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 24px;
      margin: 0 0 20px;
    }
  }
}

/** @define news-holder **/
.news-holder {
  position: relative;
  padding: 0 11px;

  @media (min-width: 1200px) {
    margin: 0 -27px;
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}

/** @define news-col **/
.news-col {
  position: relative;

  @media (min-width: 1200px) {
    padding: 0 27px;
  }

  @include media-breakpoint-down(md) {
    margin: 0 0 50px;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 0 40px;
  }
}

/** @define news-block **/
.news-block {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__image-holder {
    border: 1px solid rgba(61, 175, 207, 0.61);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }
  /* postcss-bem-linter: ignore */
  .img {
    border-radius: 6px;
  }
}

/** @define news-description **/
.news-description {
  position: relative;
  padding: 0 11px;
  display: flex;
  flex-grow: 1;

  &__wrap {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    border: 1px solid rgba(61, 175, 207, 0.61);
    border-top: 0;
    padding: 25px 18px 29px;
    position: relative;
    color: #282828;
    text-align: center;
    line-height: 22px;

    @include media-breakpoint-down(sm) {
      padding: 25px 10px 45px;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
    }
  }

  &__time {
    text-transform: uppercase;
    letter-spacing: 0.4px;
    color: #b2b2b2;
    font-weight: 700;
    display: block;
    margin: 0 0 8px;
  }

  &__text {
    margin: 0;
  }
}

/** @define more-btn **/
.more-btn {
  @include fontsize(16px, 20px);
  @include size(184px, 48px);
  padding: 14px 15px 5px;
  box-shadow: 0 2px #3b94ac;
  background: #59c8e6;
  border-radius: 4px;
  font-weight: 900;
  letter-spacing: 1.4px;
  position: absolute;
  bottom: -21px;
  left: 50%;
  margin: 0 0 0 -90px;
  border: 1px solid rgba(54, 163, 192, 0.61);
  text-transform: uppercase;
  color: $white;
  z-index: 5;
  /* postcss-bem-linter: ignore */
  &:hover {
    background: #266f83;
    color: $white;
  }
}

/* stylelint-disable */
.news-description__wrap p a {
  display: none;
}
/* stylelint-enable */

/* @define news-image-single */
.news-image-single {
  margin: 50px 0 0;
  &__img {

  }
}
